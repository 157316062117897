import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Circle = styled.circle`
  fill: none;
  stroke: ${({ $color }) => $color};
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 50% 50%;
  transform: ${({ $transform }) => $transform};
`;

const AnimatedCircle = styled(Circle)`
  transition: stroke-dasharray 500ms ease-out;
`;

const StreakCircularProgressBar = ({
  sqSize,
  percentage,
  strokeWidth,
  color,
  outerPercentage,
  className,
  animate
}) => {
  const boundedPercentage = percentage > 100 ? 100 : percentage;
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const strokeLength = 2 * Math.PI * radius;
  const rotate = 270 + (100 - outerPercentage) * 1.8;
  const transform = `rotateZ(${rotate}deg)`;
  const InnerCircle = animate ? AnimatedCircle : Circle;
  const dashArray = radius * Math.PI * 2;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox} className={className}>
      <Circle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        $color="var(--color-divider)"
        $transform={transform}
        $strokeLength={strokeLength}
        className="outer"
      />
      <InnerCircle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        $color={color}
        $transform={transform}
        $strokeLength={strokeLength}
        className="inner"
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashArray - (dashArray * boundedPercentage) / 100
        }}
      />
    </svg>
  );
};

StreakCircularProgressBar.propTypes = {
  sqSize: PropTypes.number,
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  outerPercentage: PropTypes.number,
  className: PropTypes.string,
  animate: PropTypes.bool
};

StreakCircularProgressBar.defaultProps = {
  sqSize: 200,
  percentage: 25,
  strokeWidth: 10,
  color: '',
  outerPercentage: 100,
  className: '',
  animate: false
};

export default StreakCircularProgressBar;
