import { emptyObject } from '@constants/empty';
import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';
import { useRouter } from 'next/router';

const educatorRouteRegex = new RegExp('^/educator/*');

const useActivityStats = (goalUID) => {
  const { pathname } = useRouter();
  const isEducatorRoutes = educatorRouteRegex.test(pathname);
  const key = isEducatorRoutes
    ? null
    : `v2/gamification/user/activity_stats/?goal_uid=${goalUID}`;
  const { data: activityStats, ...rest } = useFetch(goalUID ? key : null, {
    hostType: HOST_TYPE.BACKEND_API_HOST
  });
  return { activityStats: activityStats || emptyObject, ...rest };
};

export default useActivityStats;
