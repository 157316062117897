import Icon from './Icon';

const CheckCircle = (props) => (
  <Icon
    {...props}
    path="M40 7.5c-17.949 0-32.5 14.551-32.5 32.5s14.551 32.5 32.5 32.5c17.949 0 32.5-14.551 32.5-32.5v0c-0.019-17.942-14.558-32.481-32.498-32.5h-0.002zM56.667 31.567l-20.333 20.4c-0.452 0.453-1.077 0.733-1.767 0.733h-0c-0.689-0.008-1.311-0.287-1.767-0.734l0 0-9.467-9.567c-0.452-0.452-0.731-1.077-0.731-1.767s0.279-1.314 0.731-1.767v0c0.449-0.46 1.074-0.745 1.767-0.745s1.318 0.285 1.766 0.745l0 0.001 7.7 7.8 18.767-18.633c0.449-0.46 1.074-0.745 1.767-0.745s1.318 0.285 1.766 0.745l0 0.001c0.395 0.44 0.637 1.025 0.637 1.667 0 0.741-0.322 1.407-0.834 1.864l-0.002 0.002z"
  />
);

export default CheckCircle;
