import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Typography from 'aquilla/core/atoms/Typography';
import { Fonts, Spacings } from '@styles/index';
import AskADoubt from 'aquilla/icons/coach/AskADoubt';
import PlayIcon from '@icon/PlayIcon';
import Close from '@icon/Close';
import useNotificationStore from '@stores/streaks/notificationStore';
import {
  isStreaksNewFlow,
  nudgeTypes,
  storeStreaksNudgeInfo,
  STREAK_REMINDER_VIEWED
} from '@layout/Header/HeaderUser/util';
import CheckCircle from '@icon/CheckCircle';
import Image from 'next/legacy/image';
import { segment } from '@actions/analytics';
import useGoalEventProps from '@hooks/useGoalEventProps';
import StreakCircularProgressBar from '@comp/Streaks/StreakCircularProgressBar';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getMeUserId } from '@api/selectors/auth';
import StreakCircles from '../../Composite/Streaks/StreakCircles';

interface INotificationNudgeProps {
  goalUID: string;
  activityStats: any;
  questionsAttemptPercentage: number;
  dailyWatchMinPercentage: number;
}

interface MarginProps {
  $marginTop: string;
}

const Wrapper = styled.div`
  padding: ${Spacings.SPACING_4B};
  background: var(--color-base-0);
  border: ${Spacings.SPACING_1} solid var(--color-divider);
  box-shadow: ${boxShadowGenerator()};
  border-radius: ${Spacings.SPACING_2B};
  min-width: 388px;
  position: absolute;
  z-index: 10;
  top: ${Spacings.SPACING_20B};
`;

const StyP1 = styled(Typography)`
  max-width: 260px;
  margin-top: ${Spacings.SPACING_2B};
`;

const StyH4 = styled(Typography)`
  font-weight: ${Fonts.HEAVY};
  line-height: 26px;
  margin-top: ${Spacings.SPACING_4B};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemWrapper = styled.div<MarginProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${Spacings.SPACING_4B};
  margin-top: ${({ $marginTop }) => $marginTop};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacings.SPACING_2B};
`;

const StyClose = styled(Close)`
  cursor: pointer;
`;

const streaksInfo = (
  {
    watchMinutes = 0,
    watchMinsThreshold = 0,
    questionsAttempted = 0,
    questionsAttemptThreshold = 0
  },
  streakNewFlow
) => (
  <ItemWrapper
    $marginTop={streakNewFlow ? Spacings.SPACING_5B : Spacings.SPACING_4B}
  >
    <Item>
      {watchMinutes === watchMinsThreshold ? (
        <CheckCircle
          width={20}
          height={20}
          color="var(--color-i-blue-streaks)"
        />
      ) : (
        <PlayIcon />
      )}
      <Typography variant="h6">
        {`${watchMinutes} / ${watchMinsThreshold} minutes`}
      </Typography>
    </Item>
    {streakNewFlow && (
      <Item>
        {questionsAttempted === questionsAttemptThreshold ? (
          <CheckCircle
            width={20}
            height={20}
            color="var(--color-i-orange-streaks)"
          />
        ) : (
          <AskADoubt width={20} height={20} />
        )}
        <Typography variant="h6">
          {`${questionsAttempted} / ${questionsAttemptThreshold} questions`}
        </Typography>
      </Item>
    )}
  </ItemWrapper>
);

const headerInfo = (header, description) => (
  <>
    <StyH4 variant="h4">{header}</StyH4>
    <StyP1 color="var(--color-text-secondary)" variant="p2">
      {description}
    </StyP1>
  </>
);

const NotificationNudge: React.FC<INotificationNudgeProps> = ({
  goalUID,
  activityStats,
  dailyWatchMinPercentage,
  questionsAttemptPercentage
}) => {
  // @ts-ignore
  const { toggleShouldFetchActivityData } = useNotificationStore();
  const { experimentBucket = {}, nudgeData = {}, nudgeType } = activityStats;

  const { id, header, description, imageUrl } = nudgeData;
  const streakNewFlow = isStreaksNewFlow(experimentBucket);
  const goalSegmentData = useGoalEventProps({ goalUID });
  const userId = useMyInfo(getMeUserId);

  useEffect(() => {
    segment.track(STREAK_REMINDER_VIEWED, {
      ...goalSegmentData,
      nudgeType
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    toggleShouldFetchActivityData(false);
    storeStreaksNudgeInfo(id, nudgeType, userId);
  };

  const renderNewStreakNudge = () => {
    if (nudgeType === nudgeTypes.STREAK_BROKEN)
      return (
        <InnerWrapper>
          <div>{headerInfo(header, description)}</div>
          <Image
            src={imageUrl?.web}
            alt="Streak Broken"
            height={80}
            width={80}
          />
        </InnerWrapper>
      );

    return (
      <>
        {headerInfo(header, description)}
        <InnerWrapper>
          {streaksInfo(activityStats, streakNewFlow)}
          <StreakCircles
            height={80}
            width={80}
            outerSqSize={80}
            innerSqSize={48}
            outerStrokeWidth={8}
            innerStrokeWidth={8}
            dailyWatchMinPercentage={dailyWatchMinPercentage}
            questionsAttemptPercentage={questionsAttemptPercentage}
          />
        </InnerWrapper>
      </>
    );
  };

  return (
    <Wrapper>
      <StyClose
        height={Spacings.SPACING_6B}
        width={Spacings.SPACING_6B}
        onClick={onClose}
      />

      {streakNewFlow ? (
        renderNewStreakNudge()
      ) : (
        <InnerWrapper>
          <div>
            {headerInfo(header, description)}
            {streaksInfo(activityStats, streakNewFlow)}
          </div>
          <StreakCircularProgressBar
            sqSize={80}
            percentage={dailyWatchMinPercentage}
            strokeWidth={10}
            color="var(--color-i-blue-streaks)"
            outerPercentage={100}
            animate
          />
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

export default NotificationNudge;
