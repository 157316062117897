import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import styleHelper from '@utils/styleHelper';
import { Fonts, Spacings } from '@styles/index';

const H7 = styled.h6`
  font-weight: ${Fonts.SEMIBOLD};
  font-size: ${Fonts.H7};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  margin: ${Spacings.SPACING_0B};
  color: ${({ color }) => color};
  ${({ lineClamp }) =>
    lineClamp ? styleHelper.lineClampStyle(lineClamp) : null}
`;

export default H7;

H7.propTypes = {
  color: PropTypes.string,
  lineClamp: PropTypes.number
};

H7.defaultProps = {
  color: 'var(--color-text-primary)',
  lineClamp: null
};
