import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Icon from './Icon';

const StyledIcon = styled(Icon)`
  fill: none;
  height: 24px;
  width: 24px;
`;

const PlayIcon = (props) => {
  const { color, ...rest } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledIcon {...rest} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_476_228378)">
        <rect width="24" height="24" rx="12" fill="white" />
        <g clipPath="url(#clip1_476_228378)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.4997 13.5588C17.6997 12.866 17.6997 11.1339 16.4997 10.4411L11.0997 7.32339C9.89971 6.63057 8.39971 7.49659 8.39971 8.88224V15.1176C8.39971 16.5033 9.89971 17.3693 11.0997 16.6765L16.4997 13.5588Z"
            fill="#34A4FF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_476_228378">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_476_228378">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </StyledIcon>
  );
};

PlayIcon.propTypes = {
  color: PropTypes.string
};

PlayIcon.defaultProps = {
  color: 'var(--color-i-green)'
};

export default PlayIcon;
