import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Typography from 'aquilla/core/atoms/Typography';
import { Fonts, Spacings } from '@styles/index';
import Button from 'aquilla/core/molecules/Button';
import Image from 'next/legacy/image';
import BaseImage from '@base/Image';
import MUIDialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { segment } from '@actions/analytics';
import AskADoubt from 'aquilla/icons/coach/AskADoubt';
import PlayIcon from '@icon/PlayIcon';
import useGoalEventProps from '@hooks/useGoalEventProps';
import useActivityStats from '@api/hooks/activity/useActivityStats';
import {
  nudgeTypes,
  storeStreaksNudgeInfo,
  STREAKS_FEATURE_INTRODUCTION_DISMISS,
  STREAKS_FEATURE_INTRODUCTION_VIEWED
} from '@layout/Header/HeaderUser/util';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getMeUserId } from '@api/selectors/auth';

interface IIntroductionNudgeProps {
  goalUID: string;
  streakNewFlow: boolean;
  openStreaksDrawer: () => void;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const Wrapper = styled.div`
  padding: ${Spacings.SPACING_6B};
`;

const Title = styled.div`
  font-size: ${Spacings.SPACING_7B};
  font-weight: ${Fonts.SEMIBOLD};
  line-height: ${Spacings.SPACING_9B};
  margin-top: ${Spacings.SPACING_4B};
  margin-bottom: ${Spacings.SPACING_1B};
  color: var(--color-base-fill);
`;

const StyP1 = styled(Typography)`
  max-width: 384px;
  line-height: 18px;
`;

const StyH6 = styled(Typography)`
  line-height: 18px;
  font-weight: ${Fonts.SEMIBOLD};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${Spacings.SPACING_4B};
  margin-top: ${Spacings.SPACING_6B};
  margin-bottom: ${Spacings.SPACING_8B};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacings.SPACING_3B};
`;

const StyDialog = styled(MUIDialog)`
  &.MuiDialog-root .MuiDialog-container .MuiPaper-root {
    bottom: 0;
    background: var(--color-base-0);
    border-radius: ${Spacings.SPACING_4B};
    max-height: none;
    width: 464px;
    box-shadow: ${boxShadowGenerator({ color: 'rgba(0, 0, 0, 0.12)' })};
  }
  &.MuiDialog-root {
    overflow: scroll;
  }
`;

const InnerWrapper = styled.div`
  padding-left: ${Spacings.SPACING_4B};
`;

const StyButton = styled(Button)`
  border-radius: ${Spacings.SPACING_1B};
  height: ${Spacings.SPACING_10B};
  width: 140px;
`;

const Images = styled.div`
  position: relative;
`;

const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin-top: ${Spacings.SPACING_4B};
  margin-right: ${Spacings.SPACING_4B};
`;

const IntroductionNudge: React.FC<IIntroductionNudgeProps> = ({
  goalUID,
  streakNewFlow,
  openStreaksDrawer
}) => {
  const [open, setOpen] = React.useState(true);
  const { activityStats } = useActivityStats(goalUID);
  const userId = useMyInfo(getMeUserId);
  const {
    questionsAttemptThreshold,
    watchMinsThreshold,
    nudgeData = {}
  } = activityStats;

  const { header, description, imageUrl, id } = nudgeData;
  const goalSegmentData = useGoalEventProps({ goalUID });

  useEffect(() => {
    if (open) {
      segment.track(STREAKS_FEATURE_INTRODUCTION_VIEWED, {
        ...goalSegmentData,
        feature: 'Streaks'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const closeDialog = () => {
    setOpen(false);
    storeStreaksNudgeInfo(id, nudgeTypes.ONBOARDING, userId);
    segment.track(STREAKS_FEATURE_INTRODUCTION_DISMISS, {
      ...goalSegmentData,
      feature: 'Streaks'
    });
  };

  const openStreaks = () => {
    closeDialog();
    openStreaksDrawer();
  };

  return (
    <StyDialog
      open={open}
      // @ts-ignore
      TransitionComponent={Transition}
    >
      <Wrapper>
        <Images>
          <Image src={imageUrl?.web} width={416} height={227} alt="Streaks" />
          <CloseIcon onClick={closeDialog}>
            <BaseImage
              src="syllabus/close-icon.svg"
              width={Spacings.SPACING_7B}
              height={Spacings.SPACING_7B}
              alt="syllabus"
            />
          </CloseIcon>
        </Images>
        <InnerWrapper>
          <Title>{header}</Title>
          <StyP1 color="var(--color-text-secondary)" variant="p2">
            {description}
          </StyP1>
          <ItemsWrapper>
            <Item>
              <PlayIcon />
              <StyH6 variant="p2" color="var(--color-text-primary)">
                {`Watch ${watchMinsThreshold} mins of classes`}
              </StyH6>
            </Item>
            {streakNewFlow && (
              <Item>
                <AskADoubt width={24} height={24} />
                <StyH6 variant="p2" color="var(--color-text-primary)">
                  {`Attempt ${questionsAttemptThreshold} questions`}
                </StyH6>
              </Item>
            )}
          </ItemsWrapper>
          <StyButton
            // @ts-ignore
            label="View streaks"
            size="medium"
            variant="primary"
            onClick={openStreaks}
          />
        </InnerWrapper>
      </Wrapper>
    </StyDialog>
  );
};

export default IntroductionNudge;
