import styled from '@emotion/styled';
import * as React from 'react';
import StreakCircularProgressBar from './StreakCircularProgressBar';

interface IStreakCirclesProps {
  dailyWatchMinPercentage: number;
  questionsAttemptPercentage: number;
  outerSqSize: number;
  innerSqSize: number;
  outerStrokeWidth: number;
  innerStrokeWidth: number;
  width: number;
  height: number;
}

interface IDimensionProps {
  $width: number;
  $height: number;
}

const ProgressContainer = styled.div<IDimensionProps>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  position: relative;
`;

const StyledCircularProgressBar = styled(StreakCircularProgressBar)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const StreakCircles: React.FC<IStreakCirclesProps> = ({
  dailyWatchMinPercentage,
  questionsAttemptPercentage,
  outerSqSize,
  innerSqSize,
  outerStrokeWidth,
  innerStrokeWidth,
  width,
  height
}) => {
  return (
    <ProgressContainer $width={width} $height={height}>
      <StyledCircularProgressBar
        sqSize={outerSqSize}
        percentage={dailyWatchMinPercentage}
        strokeWidth={outerStrokeWidth}
        color="var(--color-i-blue-streaks)"
        animate
      />
      <StyledCircularProgressBar
        sqSize={innerSqSize}
        percentage={questionsAttemptPercentage}
        strokeWidth={innerStrokeWidth}
        color="var(--color-i-orange-streaks)"
        animate
      />
    </ProgressContainer>
  );
};

export default StreakCircles;
