/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Image from '@base/Image';
import H7 from '@base/H7/H7';
import Dynamic from '@base/Dynamic/Dynamic';
import { Spacings, mediaQuery } from '@styles/index';
import useEducatorContext from '@hooks/useEducatorContext';
import { useFlagSelector } from '@hooks/useLayoutOptions';
import { pluralize } from '@utils/textHelper';
import usePlatformInfo from '@hooks/usePlatformInfo';
import { getIsGTPGoal } from '@api/selectors/constants';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useConstants from '@api/hooks/useConstants';
import useActivityStats from '@api/hooks/activity/useActivityStats';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useCompeteConfig from '@cont/Compete/useCompeteConfig';
import ComponentErrorBoundary from '@cont/ErrorBoundary/ErrorBoundary';
import { getIsSubscribedToOffline } from '@api/selectors/subscription';
import IntroductionNudge from '@cont/MeDashboard/IntroductionNudge';
import NotificationNudge from '@cont/MeDashboard/NotificationNudge';
import useNotificationStore from '@stores/streaks/notificationStore';
import StreakItemV2 from '@cont/MeDashboard/StreakItemV2';
import { getMeUserId } from '@api/selectors/auth';
import useGoalInfo from '@api/hooks/goal/useGoalInfo';
import { isStreaksNewFlow, isStreaksNudgeVisible, nudgeTypes } from './util';
import ProfileOptions from './ProfileOptions/ProfileOptions';

const noStreakImageSrc = 'layout/header/no_streak.svg';
const currentStreakImageSrc = 'layout/header/current_streak.svg';
const longestStreakImageSrc = 'layout/header/longest_streak.svg';
const MeDashboard = Dynamic(() => import('@cont/MeDashboard/MeDashboard'));
const StreakPopup = Dynamic(() => import('./StreakPopup'));

const noStreakAssets = {
  iconSrc: noStreakImageSrc,
  bg: 'var(--color-i-gray-13)'
};

const currentStreakAssets = {
  iconSrc: currentStreakImageSrc,
  bg: 'var(--color-i-blue)'
};

const Wrapper = styled.div`
  position: relative;
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin-right: ${Spacings.SPACING_2B};
  margin-left: ${Spacings.SPACING_4B};
  padding: ${Spacings.SPACING_1B} 10px ${Spacings.SPACING_1B}
    ${Spacings.SPACING_3B};
  cursor: pointer;
  ${mediaQuery.uptoTablet} {
    grid-column: 19/25;
  }
`;

const WrapperBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: ${Spacings.SPACING_12B};
  transition: opacity 300ms ease-in-out;
  background-color: ${({ $bg }) => $bg};
  opacity: ${({ $isActive }) => ($isActive ? '0.15' : '0.05')};
  &:hover {
    opacity: 0.15;
  }
`;

const LearnedMinutesContainer = styled.div`
  display: flex;
  gap: ${Spacings.SPACING_1B};
  align-items: center;
  visibility: ${({ $isEducatorUrl }) => $isEducatorUrl && 'hidden'};
`;

const WatchMinsLabel = styled(H7)`
  line-height: 16px;
  width: max-content;
  color: ${(props) =>
    props.$changeFontColor ? 'var(--color-text-secondary)' : ''};
`;

const StreakContainer = styled(LearnedMinutesContainer)`
  grid-column-gap: ${Spacings.SPACING_1B};
  margin-left: ${Spacings.SPACING_3B};
  width: max-content;
`;

const ActivityContainer = styled(LearnedMinutesContainer)`
  grid-column-gap: ${Spacings.SPACING_1B};
  padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} ${Spacings.SPACING_1B}
    ${Spacings.SPACING_2B};
  gap: ${Spacings.SPACING_6};
`;

const HeaderUser = ({
  excludeOptions,
  isUAIconsGoal,
  isCitySpecificOfflineCentre
}) => {
  const isEducatorSide = useEducatorContext();
  const { showCompeteBackground } = useCompeteConfig();

  const {
    query: { goalUID: routeGoalUID, openDashBoard }
  } = useRouter();
  const [showMeDashboard, setShowMeDashboard] = useState(!!openDashBoard);
  useEffect(() => {
    setShowMeDashboard(!!openDashBoard);
  }, [openDashBoard]);
  const onCloseMeDashboard = () => setShowMeDashboard(false);
  const selectedGoalUID = useSelectedGoalUID();
  const goalUID = routeGoalUID || selectedGoalUID;
  const { hideWatchMinForPlatform } = usePlatformInfo();
  const isOfflineSubscription = useMyInfo(getIsSubscribedToOffline(goalUID));
  const userId = useMyInfo(getMeUserId);
  const {
    activityStats,
    isValidating,
    mutate: refetchActivityStats
  } = useActivityStats(goalUID);
  const { shouldFetchActivityData } = useNotificationStore();
  const {
    watchMinutes = 0,
    watchMinsThreshold = 0,
    currentStreak = 0,
    // longestStreak = 0,
    questionsAttempted = 0,
    questionsAttemptThreshold = 0,
    nudgeApplicable = false,
    nudgeType,
    experimentBucket = {},
    nudgeData = {}
  } = activityStats;
  const { id } = nudgeData;

  // for free subscription nudges

  const { goalInfo } = useGoalInfo(goalUID);

  const showStreaksNudge =
    nudgeApplicable &&
    isStreaksNudgeVisible(id, nudgeType, userId) &&
    !isValidating;
  const isOnboardingNudge = nudgeType === nudgeTypes.ONBOARDING;

  const streakNewFlow = isStreaksNewFlow(experimentBucket);
  const isNoStreak = currentStreak === 0;
  // eslint-disable-next-line no-nested-ternary
  const streakAssets = isNoStreak ? noStreakAssets : currentStreakAssets;
  const dailyWatchMinPercentage =
    watchMinsThreshold && watchMinutes
      ? (watchMinutes * 100) / watchMinsThreshold
      : 0;
  const questionsAttemptPercentage =
    questionsAttempted && questionsAttemptThreshold
      ? (questionsAttempted * 100) / questionsAttemptThreshold
      : 0;
  const isGTP = useConstants(getIsGTPGoal(selectedGoalUID));
  const isDailyMinStreakCompleted = !!(dailyWatchMinPercentage === 100);
  const isStreakCompleted = streakNewFlow
    ? isDailyMinStreakCompleted && questionsAttemptPercentage === 100
    : isDailyMinStreakCompleted;

  useEffect(() => {
    if (shouldFetchActivityData) refetchActivityStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchActivityData]);

  const hideLearnerMinutes =
    useFlagSelector('hideLearnerMinutes') ||
    isEducatorSide ||
    isGTP ||
    hideWatchMinForPlatform ||
    isUAIconsGoal ||
    isCitySpecificOfflineCentre;
  const [streakPopupAnchor, setStreakPopupAnchor] = useState(null);
  const openStreakPopup = (e) => {
    if (hideLearnerMinutes) return;
    setStreakPopupAnchor(e.currentTarget);
  };
  const closeStreakPopup = () => {
    if (hideLearnerMinutes) return;
    setStreakPopupAnchor(null);
  };
  const openMeDashboard = () => {
    closeStreakPopup();
    setShowMeDashboard(true);
  };

  const getPopUp = () => {
    if (goalInfo?.trialExpiryPopUpShown === undefined) return false;
    return true;
  };

  const showStreakPopup = !!streakPopupAnchor;
  const shownFreeTrialNudge = getPopUp();
  return (
    <>
      {!hideLearnerMinutes && (
        <ComponentErrorBoundary>
          {!isOfflineSubscription ? (
            <>
              <Wrapper
                onClick={showStreakPopup ? closeStreakPopup : openStreakPopup}
              >
                <WrapperBg $bg={streakAssets.bg} $isActive={showStreakPopup} />
                <LearnedMinutesContainer>
                  <StreakItemV2
                    size={20}
                    outerSqSize={20}
                    innerSqSize={14}
                    isStreaksNewFlow={streakNewFlow}
                    dailyWatchMinPercentage={dailyWatchMinPercentage}
                    questionsAttemptPercentage={questionsAttemptPercentage}
                    strokeWidth={2}
                    isCompleted={isStreakCompleted}
                  />
                  <WatchMinsLabel
                    $changeFontColor={showCompeteBackground}
                  >{`${watchMinutes} min`}</WatchMinsLabel>
                  {streakNewFlow && (
                    <WatchMinsLabel $changeFontColor={showCompeteBackground}>
                      <span>&#183;</span>
                      {` ${questionsAttempted} Q${
                        questionsAttempted === 1 ? '' : 's'
                      }`}
                    </WatchMinsLabel>
                  )}
                </LearnedMinutesContainer>
                <StreakContainer>
                  <Image
                    src={streakAssets.iconSrc}
                    alt="streak"
                    width={20}
                    height={20}
                  />
                  <WatchMinsLabel $changeFontColor={showCompeteBackground}>
                    {pluralize(currentStreak, 'day')}
                  </WatchMinsLabel>
                </StreakContainer>
                <StreakPopup
                  openMeDashboard={openMeDashboard}
                  activityStats={activityStats}
                  onClose={closeStreakPopup}
                  anchorEl={streakPopupAnchor}
                />
              </Wrapper>
              {!shownFreeTrialNudge &&
                showStreaksNudge &&
                isOnboardingNudge && (
                  <IntroductionNudge
                    goalUID={goalUID}
                    streakNewFlow={streakNewFlow}
                    openStreaksDrawer={openMeDashboard}
                  />
                )}
              {showStreaksNudge &&
                shouldFetchActivityData &&
                !isOnboardingNudge && (
                  <NotificationNudge
                    goalUID={goalUID}
                    activityStats={activityStats}
                    dailyWatchMinPercentage={dailyWatchMinPercentage}
                    questionsAttemptPercentage={questionsAttemptPercentage}
                  />
                )}
            </>
          ) : (
            <Wrapper onClick={openMeDashboard}>
              <WrapperBg $bg="var(--color-i-orange-1)" />
              <ActivityContainer>
                <Image
                  src={longestStreakImageSrc}
                  alt="activity"
                  width={18}
                  height={24}
                />
                <WatchMinsLabel> Activity </WatchMinsLabel>
              </ActivityContainer>
            </Wrapper>
          )}
        </ComponentErrorBoundary>
      )}
      {shownFreeTrialNudge}
      <ProfileOptions
        excludeOptions={excludeOptions}
        closeStreakPopup={closeStreakPopup}
      />
      <ComponentErrorBoundary>
        <MeDashboard
          showDashboard={showMeDashboard}
          onClose={onCloseMeDashboard}
        />
      </ComponentErrorBoundary>
    </>
  );
};

export default HeaderUser;

HeaderUser.propTypes = {
  excludeOptions: PropTypes.arrayOf(PropTypes.string),
  isUAIconsGoal: PropTypes.bool
};

HeaderUser.defaultProps = {
  excludeOptions: [],
  isUAIconsGoal: false
};
