import { localStorageAvailable } from '@utils/localStorage';

const oneDay = 24 * 60 * 60 * 1000;
const nowInMs = () => new Date().getTime();

export const isStreaksNewFlow = (experimentBucket = {}) => {
  const { streakNewDefinition } = experimentBucket;
  return streakNewDefinition === 'Test1';
};

export const nudgeTypes = {
  ONBOARDING: 1,
  GRACE_PERIOD: 2,
  STREAK_BROKEN: 3
};

export const storeStreaksNudgeInfo = (id, nudgeType, userId) => {
  if (localStorageAvailable()) {
    const streakNudges = localStorage.getItem('streakNudges');
    const streakNudgesObj = { ...JSON.parse(streakNudges) };
    streakNudgesObj[`${userId}-${id}-${nudgeType}`] = nowInMs();
    localStorage.setItem('streakNudges', JSON.stringify(streakNudgesObj));
  }
};

export const isStreaksNudgeVisible = (id, nudgeType, userId) => {
  if (localStorageAvailable()) {
    const streakNudges = localStorage.getItem('streakNudges');
    const streakNudgesObj = JSON.parse(streakNudges);
    if (!streakNudgesObj) return true;

    const timeStamp = streakNudgesObj[`${userId}-${id}-${nudgeType}`];
    return timeStamp ? nowInMs() - timeStamp >= oneDay : true;
  }

  return true;
};

export const STREAKS_FEATURE_INTRODUCTION_VIEWED =
  'Feature Introduction - Bottom Sheet Viewed';
export const STREAKS_FEATURE_INTRODUCTION_DISMISS =
  'Feature Introduction - Bottom Sheet Dismissed';
export const STREAK_DAILY_GOAL_VIEWED = 'Streak - Daily Goal Viewed';
export const STREAK_DAILY_GOAL_HISTORY = 'Streak - Daily Goal History';
export const STREAK_REMINDER_VIEWED = 'Streak - Reminder Viewed';
