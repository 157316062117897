import * as React from 'react';
import styled from '@emotion/styled';
import StreakCircles from '@comp/Streaks/StreakCircles';
import Check from '@icon/Check';
import Image from '@base/Image';
import StreakCircularProgressBar from '@comp/Streaks/StreakCircularProgressBar';

const progressCompleteImageSrc = 'layout/header/progress_complete.svg';
const progressCompleteV2ImageSrc = 'layout/header/progress_complete_v2.svg';

interface IStreakItemV2Props {
  dailyWatchMinPercentage: number;
  questionsAttemptPercentage: number;
  strokeWidth: number;
  isStreaksNewFlow: boolean;
  size: number;
  outerSqSize: number;
  innerSqSize: number;
  isCompleted: boolean;
}

const Wrapper = styled.div`
  position: relative;
`;

const StyCheck = styled(Check)`
  position: absolute;
  top: ${({ $position }) => $position}px;
  left: ${({ $position }) => $position}px;
`;

const StreakItemV2: React.FC<IStreakItemV2Props> = ({
  isStreaksNewFlow,
  dailyWatchMinPercentage,
  questionsAttemptPercentage,
  strokeWidth,
  outerSqSize,
  innerSqSize,
  isCompleted,
  size
}) => {
  if (isCompleted)
    return (
      <Image
        src={
          isStreaksNewFlow
            ? progressCompleteV2ImageSrc
            : progressCompleteImageSrc
        }
        alt="check rounded"
        width={size}
        height={size}
      />
    );

  if (isStreaksNewFlow) {
    return (
      <Wrapper>
        <StreakCircles
          height={size}
          width={size}
          outerSqSize={outerSqSize}
          innerSqSize={innerSqSize}
          outerStrokeWidth={strokeWidth}
          innerStrokeWidth={strokeWidth}
          dailyWatchMinPercentage={dailyWatchMinPercentage}
          questionsAttemptPercentage={questionsAttemptPercentage}
        />
        {isCompleted && (
          <StyCheck
            size={size / 2}
            color="var(--color-i-blue-streaks)"
            $position={size / 4}
          />
        )}
      </Wrapper>
    );
  }

  return (
    <StreakCircularProgressBar
      sqSize={outerSqSize}
      percentage={dailyWatchMinPercentage}
      strokeWidth={strokeWidth}
      color="var(--color-i-blue)"
      outerPercentage={95}
    />
  );
};

export default StreakItemV2;
